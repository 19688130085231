import * as React from "react";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTimes } from "@fortawesome/free-solid-svg-icons";

const AboutPage = () => {
  return (
    <Layout>
      <div className="card full-width">
        <div className="card-content">
          <h1 className="title" id="title">
            Preisliste
          </h1>

          <div className="content">
            <p>
              Aufgrund gestiegener Logistikkosten mussten die Preise für
              Wurfscheiben angepasst werden.
            </p>
            <p>
              <em>Wir bitten um Verständnis.</em>
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Preis</th>
                  <th>Kostenfrei für Mitglieder?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Standgebühr</th>
                  <td>12,50 €</td>
                  <td>
                    <span className="icon has-text-success">
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Wurfscheibe</th>
                  <td>
                    25ct / Scheibe<br />
                    <em>Ab 1.8.:</em><wbr />{' '}
                    30ct&nbsp;/&nbsp;Scheibe
                  </td>
                  <td>
                    <span className="icon has-text-grey">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Laufender Kipphase</th>
                  <td>2,50 € / 10 Durchgänge</td>
                  <td>
                    <span className="icon has-text-grey">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Fallplatte</th>
                  <td>Tagespauschale - Mitglieder 5 € / Gäste 8 €</td>
                  <td>
                    <span className="has-text-grey is-italic">
                      vergünstigt
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Leihwaffe</th>
                  <td>12,00 € / Person</td>
                  <td>
                    <span className="icon has-text-success">
                      <FontAwesomeIcon icon={faCheckSquare} />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
